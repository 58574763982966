// pagelink
// header
// sp 35 pc 88
#service {
  @include breakpoint(small){
    margin-top: -(40+35px);
    padding-top: (40+35px);
  }
  @include breakpoint(large){
    margin-top: -(80+88px);
    padding-top: (80+88px);
  }
}
#news, #recruit,
#company, #contact {
  @include breakpoint(small){
    margin-top: -(30+35px);
    padding-top: (30+35px);
  }
  @include breakpoint(large){
    margin-top: -(60+88px);
    padding-top: (60+88px);
  }
}


//
.title {
  &.service {
    @include fontSize(15px,20px,20px);
    line-height: 1;
    margin-bottom: 10px;
  }
  &.medium {
    @include fontSize(23px,23px,36px);
    line-height: 1;
    letter-spacing: 5px;
    @include breakpoint(small only){
      letter-spacing: 6.4px;
    }
  }
  &.privacy {
    @include fontSize(16px,16px,18px);
    line-height: 1;
  }

  font-family: -apple-system,BlinkMacSystemFont,'midashi';
}

.btn {
  &.color-main {
    &:hover {
      background: transparent;
      color: $white;
      background: $maincolor;
    }
    color: $maincolor;
    border: 1px solid $maincolor;
  }
  display: block;
  padding: 16px 0;
  line-height: 1;
  margin: 0 auto;
  text-align: center;
  @include width(210px,300px,300px);
}
.list {
  &.service {
    @include breakpoint(medium){
      line-height: 2;
    }
  }
  &.news {
    li {
      &:last-child {
        border-bottom: 1px solid #dedede;
      }
      border-top: 1px solid #dedede;
    }
    @include breakpoint(small){
      margin: 0 auto 20px;
    }
    @include breakpoint(medium){
      line-height: 2;
    }
    @include breakpoint(large){
      margin: 30px auto 40px;
    }
  }
  &.color-white {
    li {
      &:last-child {
        border-right: none;
      }
      border-right: 1px solid;
    }
    a {
      &:hover {
        text-decoration: underline;
        opacity: .6;
      }
      color: $white;
      @include fontSize(12px,12px,12px);
      line-height: 20px;
      padding: 0 10px;
    }
  }
  list-style: none;
  margin: 0;
}
.list-item {
  .category {
    background: $maincolor;
    color: $white;
    margin: 0 60px 0 30px;
  }
  a {
    &:hover {
      color: $maincolor;
    }
    color: $black;
  }
  @include breakpoint(small only){
    .content {
      font-size: 14px;
      margin: 5px 0 0;
      width: 100%;
    }
    .time, .category {
      font-size: 11px;
    }
    .category {
      text-align: center;
      width: 70px;
    }
  }
  @include breakpoint(small){
    .category {
      padding: 0 10px;
      margin: 0 30px 0 15px;
    }
    padding: 10px 0;
  }
  @include breakpoint(medium){
    .category {
      margin: 0 60px 0 30px;
      text-align: center;
      width: 100px;
    }
    padding: 20px 0;
    padding-left: 60px;
  }
  @include breakpoint(large){
    line-height: 32px;
  }
//  line-height: 2;
  width: 100%;
}


// custom
.topview {
  background: url("../img/main.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;
  overflow: hidden;
  width: 100%;

  @include breakpoint(small){
    height: 170px;
  }
  @include breakpoint(medium){
    height: 340px;
  }
  @include breakpoint(large){
    height: 737px;
  }
}

// custom/pages
@mixin smartImg(){
  @include breakpoint(small only){
    &::before {
      content: " ";
      display: block;
      height: 120px;
      background: url("../img/service1sp.png");
      background-repeat: no-repeat;
      background-size: 100% auto;
      margin-bottom: 20px;
      width: 100%;
    }
    background: none;
    margin: 0 auto 25px;
  }
  @include breakpoint(medium only){
    background-size: 48% auto;
  }
}
.service-single {
  &.service-single-pule {
    @include smartImg;
    background: url("../img/service1.png");
    background-repeat: no-repeat;
    background-size: 50% auto;
    background-position: left center;
  }
  &.service-single-event {
    @include smartImg;
    @include breakpoint(small only){
      &::before {
        background: url("../img/service2sp.png");
        background-repeat: no-repeat;
        background-size: 100% auto;
      }
    }
    background: url("../img/service2.png");
    background-repeat: no-repeat;
    background-size: 50% auto;
    background-position: right center;
  }
  &.service-single-aed {
    @include smartImg;
    @include breakpoint(small only){
      &::before {
        background: url("../img/service3sp.png");
        background-repeat: no-repeat;
        background-size: 100% auto;
      }
    }
    background: url("../img/service3.png");
    background-repeat: no-repeat;
    background-size: 50% auto;
    background-position: left center;
  }
  &.service-single-wash {
    @include smartImg;
    @include breakpoint(small only){
      &::before {
        background: url("../img/service4sp.png");
        background-repeat: no-repeat;
        background-size: 100% auto;
      }
    }
    background: url("../img/service4.png");
    background-repeat: no-repeat;
    background-size: 50% auto;
    background-position: right center;
  }
  &.service-single-rental {
    @include smartImg;
    @include breakpoint(small only){
      &::before {
        background: url("../img/service5sp.png");
        background-repeat: no-repeat;
        background-size: 100% auto;
      }
    }
    background: url("../img/service5.png");
    background-repeat: no-repeat;
    background-size: 50% auto;
    background-position: left center;
  }
  &.service-single-diving {
    @include smartImg;
    @include breakpoint(small only){
      &::before {
        background: url("../img/service6sp.png");
        background-repeat: no-repeat;
        background-size: 100% auto;
      }
    }
    background: url("../img/service6.png");
    background-repeat: no-repeat;
    background-size: 50% auto;
    background-position: right center;
  }
  border-top: 1px solid #ececec;
  min-height: 300px;
}
.contentItem {
  @include breakpoint(small){
    margin: 32px auto 0;
    width: 100%;
  }
  @include breakpoint(medium){
    &.left {
      margin-left: 0;
    }
    &.right {
      margin-right: 0;
    }
    &.pule {
      margin-top: 36px;
    }
    width: 50%;
  }
  @include breakpoint(large){
    margin: 64px auto 0;
  }
}

.news {
  &.l-content {
    @include breakpoint(small){
      padding: ($contents)/2 0;
    }
    @include breakpoint(large){
      padding: $contents 0;
    }
  }
}

// news-single
.newsSingle {
  .date {
    color: #8e8e8e;
  }
  .newsSingleTitle {
    @include fontSize(15px,20px,20px);
    border-bottom: 1px solid #e6e6e6;
    padding-bottom: 5px;
  }
  border-left: 8px solid $maincolor;
  padding-left: 12px;
//  margin: 0 0 10px;
}
.newsSingleContent {
  margin-top: 6px;
  line-height: 30px;
}

.company-hello {
  .helloImg {
    @include width(100%,100%,560px);
  }
  .helloText {
    @include width(100%,100%,600px);
  }
  .tables {
    @include width(100%,100%,570px);
  }
}
.helloText {
  @include breakpoint(medium down){
    margin: 10px auto 0;
  }
}

.recruit {
  &.l-content {
    background: url("../img/recruit.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    @include breakpoint(small){
      padding: $contents /2 0;
    }
    @include breakpoint(medium){
      padding: $contents 0;
    }
  }
  &.container {
    @include width(100%,610px,610px);
    padding: 0;
  }
}

.tables {
  @include breakpoint(medium down){
    margin: 0 auto 20px;
  }
}
.table.contact {
  .th {
    color: $black;
    .must {
      color: $maincolor;

      @include breakpoint(small){
        margin-left: 10px;
      }
      @include breakpoint(large){
        margin: 0;
        float: right;
      }
    }
    @include breakpoint(small){
      margin: 10px 0 0;
      width: 100%;
    }
    @include breakpoint(large){
      margin: 0;
      padding-left: 7%;
      width: 25%;
    }
  }
  .td {
    margin: 18px 0 20px;
    @include breakpoint(small){
      width: 100%;
    }
    @include breakpoint(large){
      margin-left: 8%;
      width: 67%;
    }
  }
  .input {
    @include breakpoint(small){
      font-size: 1em;
    }
    @include breakpoint(large){
      width: 450px;
    }
    &.post {
      display: inline-block;
      width: 160px;
    }

    padding-top: 5px;
    padding-bottom: 5px;
    background: #f7f7f7;
    border: solid 1px #cecece;
  }
  .select {
    background-color: #f7f7f7;
    border-radius: 0;
    -webkit-appearance: none;
    -moz-appearance: none;
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' width='32' height='24' viewBox='0 0 32 24'><polygon points='0,0 32,0 16,24' style='fill: rgb%28138, 138, 138%29'></polygon></svg>");
    background-size: 9px 6px;
    background-position: right -1rem center;
    background-origin: content-box;
    background-repeat: no-repeat;
    border: solid 1px #cecece;
    font-size: 1em;
    width: 112px;
  }
  .textarea {
    background-color: #f7f7f7;
    border: solid 1px #cecece;
    font-size: 1em;
    height: 135px;
    @include width(100%,100%,586px);
  }
  .checkbox .li {
    font-size: 1em;
    line-height: 20px;
    margin-right: 30px;
    margin-bottom: 10px;
  }
  .autofill {
    &:hover {
      background: $maincolor;
      color: $white;
    }
    border: solid 1px $maincolor;
    display: inline-block;
    height: 40px;
    line-height: 39px;
    color: $maincolor;
    @include breakpoint(small){
      padding: 0 10px;
      margin-left: 15px;
    }
    @include breakpoint(medium){
      padding: 0 22px;
      margin-left: 30px;
    }
    @include breakpoint(large){

    }
  }
  margin: 0 auto 40px;
}

.footer {
  p {
    margin-bottom: 0;
  }
  background: $maincolor;
  padding: 20px 0;
}
.bottomImg {
  margin: 0 6px 0;
  vertical-align: bottom;
}
// /custom/pages

// /custom

//extra
.mwform-checkbox-field label, .mwform-radio-field label {
  display: inline-block;
  line-height: 2.5;
}


