// 入力->確認->完了
$base: $maincolor;
$back: #ffffff;

$triangleSize: 20px 0 20px 25px;
$trianglePosition: -25px;

$triangleSizeS: 17px 0 17px 13px;
$trianglePositionS: -13px;

.form-phase {
  .phase {
    &.thanks {
      border: 1px solid $maincolor;
      background: none;
      width: 80px;
    }
    background: url("../img/path.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center;

    line-height: 34px;
    position: relative;
    text-align: center;
    width: 70px;
  }
  display: flex;
  display:-webkit-box;
  display:-webkit-flex;

  justify-content: space-between;
  flex-pack: justify;
  -webkit-box-pack: justify;

}
.mw_wp_form_input {
  .phase.typing {
    background: url("../img/pathR.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center;
    color: #fff;
  }
}
.mw_wp_form_confirm {
  .phase.confirm {
    background: url("../img/pathR.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center;
    color: #fff;
  }
}
.mw_wp_form_complete {
  .phase.thanks {
    background: $base;
    color: #fff;
  }
}


.typing {
  z-index: 3;
}
.confirm {
  z-index: 2;
}
.thanks{
  z-index: 1;
}


// 住所自動入力
$backB: #444;
$textB: #ffffff;

.mwform-checkbox-field.horizontal-item+.mwform-checkbox-field.horizontal-item {
  margin-left: 0;
}
.mwform-checkbox-field.horizontal-item {
  margin-right: 15px;
}
.zip {
  input {
    width: 30%;
  }
  #zip {
    &:active {
      background: $backB;
      color: $textB;
    }
//    @include transition(0.3s linear);
    border: 1px solid $backB;
    background: $textB;
    color: $backB;
    cursor: pointer;
    display: inline-block;
    padding: 3px 10px 5px;
    margin: 5px 0 0;
  }
}


@include breakpoint(large){
  .form-phase {
    .phase {
      &.thanks {
        width: 176px;
      }
      line-height: 40px;
      width: 170px;
    }
  }
}

