// space
$contents: 80px;
$contents: 60px;
$contentsInner: 20px;
$contentsMini: 10px;

$spaceL: 50px;
$spaceM: 30px;
$spaceS: 10px;
// /space

// color
$white: #ffffff;
$black: #333;

$gray: #f7f7f7;
$shadow: #e0e0e0;

$maincolor: #bb4444;
$maincolor-clear: rgba(187, 68, 68, 0.4);
$maincolor-light: #42c9fd;
$maincolor-dark: #0277a3;

$subcolor: #ef5350;
$subcolor-dark: #d32f2f;




// /color
