// preset
a {
  transition: 0.3s ease;
}
img,svg {
  vertical-align: top;
  height: auto;
  max-height: 100%;
  max-width: 100%;
}
label {
  cursor: pointer;
}
// /preset

// template
body {
  color: $black;
  font-family: 'Noto Sans JP','ヒラギノ角ゴシック Pro', 'Hiragino Kaku Gothic Pro', メイリオ, Meiryo, Osaka, 'MS Pゴシック', 'MS PGothic', sans-serif;
  font-weight: normal;
  margin: auto;
  -webkit-text-size-adjust: 100%;

  @include breakpoint(small){
    font-size: 12px;
    line-height: 17px;
  }
  @include breakpoint(medium){
    font-size: 14px;
    line-height: 22px;
  }
  @include breakpoint(large){
    font-size: 14px;
    line-height: 22px;
  }
}
@font-face {
  font-family: 'midashi';

  src: url("../font/A-OTF-MidashiMinPr6-MA31.woff2") format("woff2"),
    url("../font/A-OTF-MidashiMinPr6-MA31.woff") format("woff"),
    url("../font/A-OTF-MidashiMinPr6-MA31.otf") format("opentype");
  font-style: normal;
  font-weight: normal;
}

.wf-midashi {
  font-family: -apple-system,BlinkMacSystemFont,'midashi';
}


