.header {
  &.sub {
    a {
      color: $black;
    }
    .contact a {
      color: $white;
    }
    @include breakpoint(large){
      background: $white;
    }
    box-shadow: 0 1px 3px #dedede;
  }
  @include breakpoint(small){
    background: $maincolor;

    padding-top: 7px;
    height: 35px;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 9;
  }
  @include breakpoint(large){
    background: $maincolor-clear;
    padding-top: 22px;
    height: 88px;
  }
}
.logo a {
  @include breakpoint(small){
    background-image: url("../img/logo.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: calc(43px/2);
    width: calc(220px/2);
  }
  @include breakpoint(large){
    height: 43px;
    width: 220px;
  }
  display: block;
}
@include breakpoint(large){
  .header.sub .logo a {
    background-image: url("../img/logoB.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }
}


.topview {
  @include breakpoint(medium down){
    margin-top: 35px;
  }
}
.positionFixed {
  @include breakpoint(small){
    height: 35px;
  }
  @include breakpoint(medium){
    height: 88px;
  }
  @include breakpoint(large){

  }
}
.submain {
  background: #dedede;
  padding: 52px 0;
}

.globalNav {
  @include fontSize(18px,18px,18px);
  @include breakpoint(medium down){
    .left {
      width: 110px;
    }
  }
  @include breakpoint(large){
    .right {
      width: 700px;
    }
  }
}
.globalItem {
  &.contact a {
    background: $maincolor;
    border: 1px solid $maincolor;
    padding: 10px 21px;
  }
  a {
    &:hover {
      color: $maincolor;
      background: transparent;
    }
    display: block;
    color: $white;
  }
  &.active {
    .icon.close {
      display: block;
    }
    .icon.open {
      display: none;
    }
  }

  .icon.close {
    display: none;
  }
}
.globalsp {
  .item a {
    &::after {
      content: ">";
      display: block;
      float: right;
    }
    &:hover {
      background: $maincolor;
      color: $white;
    }
    border-bottom: 1px solid $maincolor;
    color: $maincolor;
    padding: 0 20px;
    height: 33px;
    line-height: 33px;
    display: block;
    width: 100%;

  }
  .contact {
    &:hover {
      background: $white;
      color: $maincolor;
    }
    border: 1px solid $maincolor;
    background: $maincolor;
    color: $white;
    display: block;
    margin: 20px auto;
    padding: 12px 0;
    text-align: center;
    width: 140px;
  }
  position: fixed;
  top: 35px;
  left: 50%;
  width: 50%;
  background: #fff;
  height: 100vh;

  display: none;
  z-index: 10;
}

.icon {
  &.tel {
    background-image: url("../img/telW.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    display: block;
    height: 16px;
    width: 16px;
  }
  &.mail {
    background-image: url("../img/mailW.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    display: block;
    height: 17px;
    width: 23px;
  }
  &.close {
    background-image: url("../img/close.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    display: block;
    height: 20px;
    width: 20px;
  }
  &.open {
    background-image: url("../img/open.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    display: block;
    height: 20px;
    width: 20px;
  }
  margin-left: 25px;
}
.breadcrumb {
  margin: 10px 0 5px;
  line-height: 1;
}
.pagenation {
  &.single {
    a {
      border-style: solid;
      padding: .3em 1.2em;
      margin: 0 4em;
      @include breakpoint(small){
        margin: 0 .5em;
      }
      @include breakpoint(medium){
        margin: 0 2em;
      }
      @include breakpoint(large){
        margin: 0 4em;
      }
    }
  }
  a,span {
    border: 1px dotted $maincolor;
    color: $black;
    padding: .1em 1em;
    margin: 0 1em;
  }
  a:hover ,span {
    background: $maincolor;
    color: $white;
  }
  @include breakpoint(small){

  }
  @include breakpoint(medium down){
    a,span {
      margin: .5em 1em;
    }
  }
}
.googlemap {
  iframe {
    @include breakpoint(medium down){
      height: 240px;
    }
    @include breakpoint(large){
      height: 400px;
    }
    max-width: 100%;
  }
  text-align: center;
  width: 100%;
}




