// font-family
.wf-sawarabigothic { font-family: "Sawarabi Gothic"; }
.wf-sawarabimincho { font-family: "Sawarabi Mincho"; }
.wf-mplus1p { font-family: "Mplus 1p"; }
.wf-noto { font-family: 'Noto Sans JP', sans-serif; }

// font-size
@mixin fontSize($smallSize,$mediumSize,$largeSize){
  @include breakpoint(small){
    font-size: $smallSize;
  }
  @include breakpoint(medium){
    font-size: $mediumSize;
  }
  @include breakpoint(large){
    font-size: $largeSize;
  }
}
.h1 { @include fontSize(24px,48px,48px); }
.h2 { @include fontSize(20px,40px,40px); }
.h3 { @include fontSize(19px,31px,31px); }
.h4 { @include fontSize(18px,25px,25px); }
.h5 { @include fontSize(17px,20px,20px); }
.h6 { @include fontSize(16px,16px,16px); }
.s7 { @include fontSize(12px,12px,12px); }
.s8 { @include fontSize(11px,11px,11px); }
.s9 { @include fontSize(9px,9px,9px); }

// line-height
.line10 { line-height: 1; }
.line15 { line-height: 1.5; }
.line18 { line-height: 1.8; }
.line20 { line-height: 2; }

// color
.color-main { color: $maincolor; }
.color-white { color: $white; }

.back-white { background: $white; }
.back-black { background: $black; }
.back-main { background: $maincolor; }
.back-main-clear { background: $maincolor-clear; }
.back-gray { background: $gray; }

// br
.smartLeft {
  @include breakpoint(small){
    text-align: left;
  }
  @include breakpoint(large){
    text-align: center;
  }
}
.smartCenter {
  @include breakpoint(small){
    text-align: center;
  }
  @include breakpoint(medium){
  }
  @include breakpoint(large){
    text-align: left;

  }
}

// layout width
@mixin width($smallSize,$mediumSize,$largeSize){
  @include breakpoint(small){
    width: $smallSize;
  }
  @include breakpoint(medium){
    width: $mediumSize;
  }
  @include breakpoint(large){
    width: $largeSize;
  }
}
.container {
  &.large {
    @include breakpoint(large only){
      .helloImg, .helloText,.tables
      {
        max-width: 48%;
      }
      .table .td {
        width: 280px;
      }
    }
    @include breakpoint(xlarge){
      width: 1200px;
    }
  }
  &.expanded {
    @include width(100%,100%,100%);
    @include breakpoint(medium down){
      padding: 0;
    }
  }
  &.small {
    @include width(100%,100%,800px);
  }

  @include width(100%,100%,1000px);
  @include breakpoint(medium down){
    padding: 0 20px;
  }
  margin: 0 auto;
}

// layout margin
.l-content {
  &.top {
    margin: $contents + 20 auto 0;
  }
  margin: $contents auto 0;
  @include breakpoint(small){

  }
  @include breakpoint(medium down){
    &.top {
      margin: ($contents + 20)/2 auto 0;
    }
    margin: ($contents)/2 auto 0;
  }
}
.l-content-inner {
  margin: 0 auto ($contents + $contentsInner)/2;
  @include breakpoint(small){

  }
  @include breakpoint(medium down){
    margin: 0 auto ($contents + $contentsInner)/4;
  }
}
.l-inner {
  margin: 0 auto $contentsInner;
}
.l-half {
  @include breakpoint(small){

  }
  @include breakpoint(medium down){
    margin: 0 auto ($contents / 2)/2;
  }
  @include breakpoint(large){
    margin: 0 auto $contents / 2;
  }
}
.l-center {
  display: block;
  margin: 0 auto;
}
.l-title {
  margin: 0 auto $contentsInner / 2;
}
.l-margin1em {
  margin: 0 1em;
}

// layout iblock
.l-iblock {
  .li {
    display: inline-block;
  }
}

// layout flex
.row {
  &.flex-reverse {
    -webkit-flex-direction: row-reverse;
    flex-direction:         row-reverse;
  }
}
.flex {
  &.flex-reverse {
    -webkit-flex-direction: row-reverse;
    flex-direction:         row-reverse;
  }
  &.align-middle {
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  &.align-justify {
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
  &.align-center {
    webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-flow: row wrap;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
}

// layout valiation
.wide {
  overflow: hidden;
}

// common class
.transition {
  transition: 0.3s ease;
}
.relative {
  position: relative;
}
.bold {
  font-weight: 600;
}
.opacity:hover {
  opacity: .6;
}

// common parts
.button {
  &:hover {
    color: $maincolor;
    background: none;
  }
  border: 1px solid $maincolor;
  background: $maincolor;
  color: $white;
}
.table {
  &.small {
    .th {
      @include breakpoint(small){
        margin: 8px 15px 0;
        width: 100%;
      }
      @include breakpoint(medium){
        margin: 18px 30px 20px 60px;
        width: 90px;
      }
    }
    .td {
      @include breakpoint(small){
        margin: 0 15px 12px;
        width: 100%;
      }
      @include breakpoint(medium){
        margin: 18px 0 20px;
        width: 380px;
      }
    }
  }
  .col {
    &:last-child {
      border-bottom: 1px solid #ccc;
    }
    border-top: 1px solid #ccc;
    width: 100%;
  }
  .th {
    color: $maincolor;
    @include breakpoint(small){
      margin: 8px 15px 0;
      width: 100%;
    }
    @include breakpoint(medium){
      margin: 18px 50px;
      width: 100px;
    }
  }
  .td {
    @include breakpoint(small){
      margin: 0 15px 12px;
      width: 100%;
    }
    @include breakpoint(medium){
      margin: 16px 0;
      width: 350px;
    }
  }

  margin: 0 auto 1em;
  width: 100%;
}

.pagenation {
  a,span {
    border: 1px solid $maincolor;
    padding: .1em 1em;
    margin: 0 1em;
  }
  a:hover ,span {
    background: $maincolor;
    color: $white;
  }
}
.bordered {
  border: 1px solid #ccc;
}

// form
.input {
  margin: 0;
}
.submit {
  input {
    &:hover {
      background: $maincolor;
      color: $white;
    }
    border: 1px solid $maincolor;
    background: transparent;
    color: $maincolor;
    display: block;
    line-height: 1;
    margin: 0 30px;
    padding: 15px 0;
    text-align: center;
    @include width(60%,270px,270px);
    transition: 0.3s ease;
  }
}

// checkbox or radio
.checkbox {
  &.square {
    input[type="checkbox"]:checked::before,
    input[type="checkbox"]
    {
      border-radius: 0;
    }
  }
  input[type="checkbox"] {
    &:checked::before {
      background-color: $maincolor;
      border-radius: 50%;
      content: " ";
      display: block;
      position: absolute;
      top: 3px;
      left: 3px;
      height: 12px;
      width: 12px;
    }
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    background-color: #f7f7f7;
    border-radius: 50%;
    border: solid 1px #cecece;
    margin: 0 .5em 0 0;
    position: relative;
    height: 20px;
    width: 20px;
    vertical-align: middle;
  }
}
.radio {
  input[type="radio"] {
    &:checked::before {
      background-color: #ed959e;
      border-radius: 50%;
      border: solid 1px #ed959e;

      content: " ";
      display: block;
      position: absolute;
      top: 3px;
      left: 3px;
      height: 12px;
      width: 12px;
    }
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    background-color: #fff2f4;
    border-radius: 50%;
    border: solid 1px #cecece;
    margin: 0 .5em 0 0;
    position: relative;
    height: 20px;
    width: 20px;
    vertical-align: middle;
  }
}

// pricacy
.privacycheck {
  .privacycheckInner {
    background-color: #f7f7f7;
    border: solid 1px #cecece;
    height: 166px;
    overflow: auto;

    @include breakpoint(small){
      margin: 0 auto 15px;
      padding: 15px;
      width: 100%;
    }
    @include breakpoint(large){
      padding: 10px 70px 30px;
      margin: 0 auto 30px;
      width: 980px;
    }
  }
}


// extra
.textlink {
  &:hover {
    color: inherit;
    text-decoration: underline;
  }
  color: $black;
}

